.footer {
  background: var(--primary-color);
  color: var(--background-color);
  padding: 24px;
  padding-bottom: 80px;
  font-family: var(--font-mono);
}

html[data-theme='dark'] {
  .footer {
    background: #313131;
    color: #FFF;
  }
}

.innerFooter {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--max-width) - 400px);
}

.columnOne {
  flex: 1;
  padding: 20px;
  max-width: 600px;
  min-width: 350px;
  margin: 0 auto;
}

.upperCell {
  margin-bottom: 48px;
}

.lowerCell {
  font-size: 0.8rem;
  text-align: center;
  opacity: 0.3;
}

.columnTwo {
  flex: 1;
  padding: 20px;
  max-width: 700px;
  min-width: 350px;
  margin: 0 auto;

}

.linkWrapper {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.footerLogo {
  width: calc(422.1252px);
  height: calc(166.9062px);
}

.socialMediaBarContainer {
  margin-top: 24px;
  max-width: 200px;
}

.columnFooter {
  font-family: var(--font-roboto);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  // justify-content: space-between;
  // padding-top: 1.4rem;
}
.column {
  // background: red;
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;

  h5 {
    width: 100%;
    font-size: 1rem;
    // margin-bottom: 0.5rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  a {
    opacity: 0.7;
  }
}

.link {
  // padding: 2.5px;
  // margin-bottom: 10px;
  // margin-right: 10px;
  font-size: 0.8rem;
  padding-top: 2px;
  /* animation 2.5s all */
  transition: 0.2s all;
}

.link:hover {
  color: var(--default-color);
  text-decoration: underline;
}

@media screen and (max-width: 850px) {
  .innerFooter {
    display: block;
  }

  .upperCell {
    margin-left: auto;
    margin-right: auto;
    width: calc(422.1252px);
    margin-bottom: 0px;
  }

  .columnOne {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 300px;
  }

  .columnTwo {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    // text-align: center;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .lowerCell {
    text-align: center;
  }

  .socialMediaBarContainer {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    padding: 40px;
  }
}

@media screen and (max-width: 1600px) {
  .innerFooter {
    max-width: calc(var(--max-width) - 800px);
  }
}

@media screen and (max-width: 450px) {
  .footer {
    padding: 0px;
    width: 100%;
  }

  .footerLogo {
    width: calc(422.1252px * 0.7);
    height: calc(166.9062px * 0.7);
    margin: 0 auto;
  }

  .columnOne {
    padding: 0px;
    margin: 0 auto;
    width: 100%;
    padding: 2rem 0;
  }

  .upperCell {
    margin-bottom: 24px;
  }

  .columnTwo {
    padding: 0px;
    margin: 0 auto;
  }

  .columnFooter {
    padding: 0;
    padding: 1rem;
  }

  .socialMediaBarContainer {
    margin-left: auto;
    margin-right: auto;
  }

  // .column {
  //   width: 100%;
  //   // text-align: center;
  //   margin: 0;
  //   display: flex;
  //   flex-wrap: wrap;
  //   flex-direction: row;
  //   margin-bottom: 1rem;
  //   gap: 0.5rem;

  //   h5 {
  //     width: 100%;
  //   }
  // }

  // .link {
  //   font-weight: 500;
  //   font-size: 0.9rem;
  //   padding: 12px 21px;
  //   // min-width: 150px;
  //   text-align: center;
  //   border: 1px solid #FFF;
  //   border-radius: 20px;
  // }

  .lowerCell {
    padding-bottom: 1rem;
  }
}
