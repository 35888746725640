.outerWrapper {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  width: 100vw;
  border-bottom: 1px solid var(--faint-border-color);
  // overflow-x: scroll;
  background: var(--background-color);
}

.innerWrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.innerWrapperBig {
  width: 1277px;
}

.innerWrapperSmall {
  width: 830px;
}

.leftSection {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 26px;
}

.logo {
  padding: 12px;
}

.search {
  display: flex;
  background: #FAFAFA;
  border-radius: 100px;
  border: 1px solid var(--faint-border-color);
  margin-left: 1.2rem;

  input {
    padding: 12px 8px;
    // border-radius: 100px;
    color: #000;
    font-size: 1rem;
    width: 300px;
    background: #FAFAFA;
    border: 0px;

    &:focus {
      outline: none;
    }
  }
}

.searchIcon {
  margin: 10px 0px 10px 10px;
  opacity: 0.5;
}

.closeIcon {
  margin: 10px 10px 10px 0px;
  opacity: 0.5;
}

.rightSection, .modalButton {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 26px;
}

.logoName {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-text-color);
}

.modalButton {
  display: none;
}

@media screen and (max-width: 450px) {
  .leftSection {
    margin-left: 2rem;
  }
  .search {
    display: none;
  }
  .hideWhenSmall {
    display: none;
  }

  .modalButton {
    display: contents;
  }
}
