.fullWidthWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 2rem 0; // Optional: vertical padding
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 568px;
  z-index: 1; // Ensures content stays above image
}

.imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0; // Background stays behind content
}

.bgImage {
  object-fit: cover;
  object-position: center top;
  width: 100%;
  height: 100%;
  filter: brightness(0.75);
}

.left {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
}

.right {
  padding: 1rem;
  margin: 0 auto;
  max-width: 600px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}

.subtitle {
  margin-bottom: 2rem;
  font-family: var(--font-roboto);
  line-height: 2;
}

.navSection {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.navLeft,
.navRight {
  margin: 0 auto;
}

.navLeft {
  max-width: 500px;
}

.plantImage {
  border-radius: 7px;
  width: 100%;
  height: auto;
}

.navRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subLinks {
  font-size: 1rem;
  padding: 1.25rem 0;
  line-height: 2;

  a {
    position: relative;
    text-decoration: none;
    color: inherit;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 1px; // Thinner underline (original was 2px)
      width: 0;
      background-color: currentColor;
      transition: width 0.6s ease, // Slower animation (original was 0.3s)
        left 0.6s ease;
    }

    &:hover {
      color: var(--secondary-font-rgb);

      &::after {
        width: 100%;
        left: 0;
      }
    }

    &:nth-child(1) {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }
}

.left {
  // position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 80vh;
}

@media screen and (max-width: 1200px) {

  .title,
  .subtitle,
  .callToAction {
    text-align: center;
  }

  .callToAction {
    margin: 0 auto;
  }

  .left {
    // padding-top: 0;
    min-height: auto;
    padding: 1rem auto 0px auto;
    padding-bottom: 0px;
  }

  .right {
    padding-top: 0px;
  }

  .subLinks {
    padding: 1.25rem;
    padding-bottom: 0px;
  }
}
