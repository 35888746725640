.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button {

}

.drawer {
  div:nth-child(3) {
    width: 250px;
    // background: red;
  }
}

.header {
  margin-right: auto;
}
