// .wrapperMain {
//   display: flex;

//   .sidebar {
//     width: 200px;
//   }

//   .main {
//     flex: 1;
//   }
// }

// .wrapperCollapsed {
//   width: 100%;
//   // this is a hack to make the sidebar disappear
//   margin-top: -200px;

//   .sidebar {
//     width: 0px;
//   }

//   .main {
//     width: 100%;
//   }
// }

.main {
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  // justify-content: flex-end;
}

.content {
  flex: 1;
  // margin-bottom: 10rem;
}
